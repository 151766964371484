import { SvgIcon } from 'material-ui'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import React from 'react'
import TextField from '@material-ui/core/TextField'

type Props = {
  isCompleted: boolean
  todoText: string
  onCompleted: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChangeTodoText: (e: React.ChangeEvent<HTMLInputElement>) => void
  onDeleteButton: () => void
}

const TodoComp = (props: Props) => (
  <ListItem>
    <Checkbox checked={props.isCompleted} onChange={props.onCompleted} />
    <TextField color='secondary' value={props.todoText} onChange={props.onChangeTodoText} />
    <IconButton onClick={props.onDeleteButton}>
      <CancelOutlinedIcon color='secondary' />
    </IconButton>
  </ListItem>
)

export default TodoComp
