import './App.css'
import { Container } from '@material-ui/core'
import { shadows } from '@material-ui/system'
import ActiveFilterButtons from './Components/ActiveFilterButtons'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import List from '@material-ui/core/List'
import React from 'react'
import TextField from '@material-ui/core/TextField'
import Todo from './Components/TodoComp'
import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

const ComponentWrapper = styled.div`
  display: grid;
  padding-bottom: 2rem;
`

const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const TodoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`

const MidContainer = styled.div`
  display: grid;
  background: white;
  text-align: center;
  overflow-y: auto;
`

const RootContainer = styled.div`
  display: grid;
  height: 100vh;
  overflow-y: auto;
  font-family: 'Trebuchet MS';
`

const SideContainer = styled.div`
  display: grid;
  overflow-y: auto;
`

type FilterStatus = 'all' | 'active' | 'completed'
type Todo = {
  id: string
  check: boolean
  todoText: string
}

type Props = {}
type State = {
  inputText: string
  list: Todo[]
  filterStatus: FilterStatus
  showTodos: boolean
}

const createID = () => Math.random().toString(36).substring(2, 7)

const getLocalStorageJSON = (key: string) => {
  const localState = localStorage.getItem(key)
  if (typeof localState !== 'string') return null
  if (!localState) return null
  try {
    return JSON.parse(localState)
  } catch (error) {
    console.error(error)
    return null
  }
}

class TodoList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const defaultState = {
      inputText: '',
      list: [
        { id: createID(), check: false, todoText: 'ukol1' },
        { id: createID(), check: true, todoText: 'ukol2' },
      ],
      filterStatus: 'all' as const,
      showTodos: true,
    }
    this.state = getLocalStorageJSON('state') ?? defaultState
  }

  onDeleteButton = (todo: Todo) => {
    this.mySetState(prevState => ({
      list: prevState.list.filter(todoToUpdate => todoToUpdate.id !== todo.id),
    }))
  }

  mySetState = (arg1: ((a: State) => any) | Partial<State>, arg2?: any) => {
    this.setState(arg1 as any, () => {
      localStorage.setItem('state', JSON.stringify(this.state))
      arg2?.()
    })
  }

  onChangeTodoText = (e: React.ChangeEvent<HTMLInputElement>, todo: Todo) => {
    const newText = e.target.value
    this.mySetState(prevState => ({
      list: prevState.list.map(todoToUpdate =>
        todoToUpdate.id === todo.id ? { ...todoToUpdate, todoText: newText } : todoToUpdate
      ),
    }))
  }

  onCompleted = (e: React.ChangeEvent<HTMLInputElement>, todo: Todo) => {
    const isActive = e.target.checked

    this.mySetState(prevState => ({
      list: prevState.list.map(stateTodo => {
        if (stateTodo.id === todo.id) {
          return {
            ...stateTodo,
            check: isActive,
          }
        }
        return stateTodo
      }),
    }))
  }

  render() {
    return (
      <RootContainer className='grid'>
        <SideContainer className='sidebar'></SideContainer>
        <MidContainer>
          <Typography color='secondary' variant='h1'>
            <div style={{ fontFamily: 'Brush Script MT', paddingTop: '1rem' }}>TODO</div>
          </Typography>
          <ComponentWrapper>
            <FormWrapper>
              <form
                onSubmit={() => {
                  const newText = this.state.inputText
                  if (newText === '') {
                    alert('You have to fill input')
                    return
                  }
                  this.mySetState(prevState => ({
                    list: [
                      { id: createID(), todoText: prevState.inputText, check: false },
                      ...prevState.list,
                    ],
                    inputText: '',
                  }))
                }}
              >
                <TextField
                  color='secondary'
                  label='New TODO'
                  background-color='white'
                  id='outlined-size-small'
                  defaultValue='Small'
                  variant='outlined'
                  size='small'
                  value={this.state.inputText}
                  onChange={e => {
                    this.mySetState({ inputText: e.target.value })
                  }}
                ></TextField>
                <Button
                  style={{
                    height: '1.1876em',
                    paddingTop: '19px',
                    paddingBottom: '19px',
                  }}
                  variant='outlined'
                  color='default'
                  onClick={() => {
                    const newText = this.state.inputText
                    if (newText === '') {
                      alert('You have to fill input')
                      return
                    }
                    this.mySetState(prevState => ({
                      list: [
                        { id: createID(), todoText: prevState.inputText, check: false },
                        ...prevState.list,
                      ],
                      inputText: '',
                    }))
                  }}
                >
                  add
                </Button>
              </form>
            </FormWrapper>
          </ComponentWrapper>
          <ComponentWrapper>
            <TodoWrapper>
              <Card style={{ boxShadow: '0px 0px 20px 0px rgba(50, 50, 50, 0.2)' }}>
                <List style={{ visibility: this.state.showTodos ? 'inherit' : 'hidden' }}>
                  {this.state.list
                    .filter(todo => {
                      if (this.state.filterStatus === 'all') {
                        return true
                      } else if (this.state.filterStatus === 'active') {
                        return todo.check ? false : true
                      } else if (this.state.filterStatus === 'completed') {
                        return todo.check ? true : false
                      } else {
                        return false
                      }
                    })
                    .map(todo => (
                      <Todo
                        key={todo.id}
                        isCompleted={todo.check}
                        onCompleted={e => this.onCompleted(e, todo)}
                        todoText={todo.todoText}
                        onChangeTodoText={e => this.onChangeTodoText(e, todo)}
                        onDeleteButton={() => this.onDeleteButton(todo)}
                      />
                    ))}
                </List>
              </Card>
            </TodoWrapper>
          </ComponentWrapper>
          <ComponentWrapper style={{ paddingBottom: '0px' }}>
            <ButtonsWrapper>
              <ActiveFilterButtons
                filterStatus={this.state.filterStatus}
                onChangeFilterStatus={newFilterStatus =>
                  this.mySetState({ filterStatus: newFilterStatus })
                }
              />
            </ButtonsWrapper>
            <ButtonsWrapper>
              <Button
                variant={!this.state.showTodos ? 'contained' : 'outlined'}
                color='default'
                size='small'
                onClick={() => {
                  this.mySetState(prevState => ({ showTodos: !prevState.showTodos }))
                }}
              >
                Toggle Todos
              </Button>
              <Button size='small' variant='outlined' onClick={() => this.mySetState({ list: [] })}>
                clear all
              </Button>
            </ButtonsWrapper>
          </ComponentWrapper>
          <div>
            <h4>items: {this.state.list.length}</h4>
          </div>
        </MidContainer>
        <SideContainer className='sidebar'></SideContainer>
      </RootContainer>
    )
  }
}

export default TodoList
